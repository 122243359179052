<template>
  <div v-if="langLoaded" class="pa-5">
    <v-card
      class="mx-auto"
    >
      <v-card-title>
        <h2 v-t="'CategoriesForm.CategoryEditing'"></h2>
        <v-spacer></v-spacer>
        <v-btn color='secondary' v-t="'General.Return'" @click="$router.push({ name: 'Categories' })"></v-btn>
      </v-card-title>
      <v-form v-model="valid" ref="form">
        <v-tabs background-color="grey lighten-4" v-model="tab" grow>
          <v-tab v-t="'CategoriesForm.GeneralInformations'">
          </v-tab>
          <v-tab v-t="'CategoriesForm.ProductsManagement'"></v-tab>
          <v-tab-item>
            <div class="mt-5"></div>
            <v-container>
              <v-row>
                <v-col
                >
                  <v-text-field
                      v-model="currentCategory.name"
                      :label="$t('CategoriesForm.CategoryName')"
                      required
                      outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-card outlined>
                <v-card-title v-t="'General.ListTranslations'"></v-card-title>
                <v-card-text>
                  <v-row class="align-center" v-for="lang in currentCategory.labelList" :key="lang.langValue">
                    <v-col v-if="languageList && languageList.find((l) => { return l.value === lang.langValue })">
                      <v-chip>  {{ languageList.find((l) => { return l.value === lang.langValue }).country }}</v-chip>
                    </v-col>
                    <v-col v-if="languageList && languageList.find((l) => { return l.value === lang.langValue })">
                      <v-text-field
                          v-model="lang.label"
                          required
                          outlined
                          clearable
                          :label="$t('CategoriesForm.CategoryNameIn') + languageList.find((l) => { return l.value === lang.langValue }).country"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-container>
            <v-row>
              <v-col>
                <v-file-input
                  v-model="inputFileImage"
                  accept="image/*"
                  @change="showPrefixPreview = false"
                  outlined
                  :clearable="false"
                  :label="$t('CategoriesForm.AddPreview')"
                  class="mx-5"
                  color="primary"
                  :prefix="previewComputed"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <div class="mt-5"></div>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th></th>
                  <th v-t="'CategoriesForm.ProductName'" class='text-center'></th>
                  <th v-t="'General.SKU'" class='text-center'></th>
                </tr>
                </thead>
                <tbody id="listeProduits">
                <tr v-for='product in products' :key='product.id' class="draggable">
                  <td>
                    <div style="display: flex; justify-content: center;">
                      <v-icon class="icon-move">mdi-cursor-move</v-icon>
                    </div>
                  </td>
                  <td>{{ product.name }}</td>
                  <td>{{ product.sku }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
        </v-tabs>
      </v-form>
      <div class='float-right pa-3' style="display: flex; align-items: center">
        <v-switch
            style="margin-right: 30px"
            v-model="currentCategory.published"
            :label="$t('General.Publish')"
        ></v-switch>
        <v-btn :disabled="!valid" color='primary' @click='addOrModifyCategory'>{{ $route.params.id.indexOf('new') === -1
          ? $t('General.Edit') : $t('General.Add') }}
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import Sortable from 'sortablejs'
  export default {
    name: 'CategoriesForm',
    data () {
      return {
        valid: false,
        currentCategory: {
          id: '',
          name: '',
          labelList: [],
          published: false,
          previewPath: ''
        },
        categories: [],
        languageList: [],
        langLoaded: false,
        products: [],
        tab: 0,
        inputFileImage: {},
        showPrefixPreview: true,
        uploadingImage: false
      }
    },
    computed: {
      previewComputed () {
        return this.showPrefixPreview ? this.getFileName(this.currentCategory.previewPath) : ''
      }
    },
    watch: {
      tab (val) {
        if (val === 1) {
          setTimeout(() => {
            Sortable.create(document.getElementById('listeProduits'), {
              handle: '.icon-move',
              animation: 150,
              draggable: '.draggable',
              onEnd: (evt) => {
                var products = this.products
                var productToMove = products[evt.oldIndex]
                if (evt.newIndex > evt.oldIndex) {
                  for (let i = evt.oldIndex; i < evt.newIndex; i++) {
                    products[i] = products[i + 1]
                  }
                } else if (evt.newIndex < evt.oldIndex) {
                  for (let i = evt.oldIndex; i > evt.newIndex; i--) {
                    products[i] = products[i - 1]
                  }
                }
                products[evt.newIndex] = productToMove
                this.products = products
              }
            })
          }, 50)
        }
      }
    },
    async mounted () {
      if (this.$route.params.id.indexOf('new') === -1) {
        await this.getCurrentCategory()
        await this.getProducts()
        this.products = this.products.filter((product) => { return product.categoryId === this.currentCategory.id })
        this.products.forEach((product) => {
          product.poids = (!product.poids && product.poids !== 0) ? 10000 : product.poids
        })
        this.products.sort((a, b) => a.poids - b.poids)
      }
      await this.$store.dispatch('getAllSites').then(() => {
        this.sites = this.$store.getters['GET_SITES']()
        this.languageList = []
        this.sites.forEach((site) => {
          site.languageList.forEach((langToAdd) => {
            if (!this.languageList.find((lang) => {
              return lang.value === langToAdd.value
            })) {
              this.languageList.push(langToAdd)
            }
          })
        })
        this.languageList.forEach((lang) => {
          if (!this.currentCategory.labelList.find((langLabel) => {
            return langLabel.langValue === lang.value
          })) {
            this.currentCategory.labelList.push({ langValue: lang.value, label: '' })
          }
        })
        this.langLoaded = true
      })
    },
    methods: {
      getFileName (name) {
        if (!name) {
          return ''
        }
        name = name.split('_')
        name.shift()
        return name.join('')
      },
      async getCurrentCategory () {
        return this.$store.dispatch('getCategory', this.$route.params.id).then(() => {
          this.currentCategory = this.$store.getters['GET_CURRENT_CATEGORY']()
          if (!this.currentCategory.previewPath || this.currentCategory.previewPath === '') {
            this.currentCategory.previewPath = ''
          }
          if (this.currentCategory === null) {
            this.$emit('notify', {
              color: 'red',
              text: this.$t('CategoriesForm.CategoryNotFound')
            })
            this.$router.push({ name: 'Categories' })
          }
        })
      },
      async getProducts () {
        return this.$store.dispatch('getAllProducts').then(() => {
          this.products = this.$store.getters['GET_PRODUCTS']()
        })
      },
      addOrModifyCategory () {
        this.$refs.form.validate()
        if (this.valid) {
          if (this.currentCategory.id) {
            this.modifyCategory()
          } else {
            this.addCategory()
          }
        }
      },
      addCategory () {
        const categories = this.$store.getters['GET_CATEGORIES']()
        this.currentCategory.index = categories.length
        this.$store.dispatch('addCategory', this.currentCategory).then(() => {
          this.currentCategory = this.$store.getters['GET_CURRENT_CATEGORY']()
          this.uploadImage()
          this.$router.push({ name: 'Categories' })
        }).catch((err) => {
          this.$emit('notify', {
            color: 'red',
            text: this.$t('Messages.' + err.response.data)
          })
        })
      },
      modifyCategory () {
        const data = {}
        for (let i = 0; i < this.products.length; i++) {
          this.products[i].poids = i
          data.product = this.products[i]
          this.$store.dispatch('editProduct', data)
        }
        this.$store.dispatch('editCategory', this.currentCategory).then(() => {
          this.uploadImage()
          this.$router.push({ name: 'Categories' })
        }).catch((err) => {
          this.$emit('notify', {
            color: 'red',
            text: this.$t('Messages.' + err.response.data)
          })
        })
      },
      uploadImage () {
        if (this.inputFileImage.name) {
          this.uploadingImage = true
          const reader = new FileReader()

          reader.onload = () => {
            const data = new FormData()
            const blob = new Blob([reader.result])
            data.append('fileName', this.inputFileImage.name)
            data.append('id', this.currentCategory.id)
            data.append('file', blob)

            this.$store.dispatch('uploadCategoryPreview', data).then(() => {
              this.uploadingImage = false
              this.currentCategory.previewPath = this.inputFileImage.name
            }).catch((err) => {
              this.$emit('notify', {
                color: 'red',
                text: this.$t('Messages.' + err.response.data)
              })
              this.uploadingImage = false
            })
          }

          reader.readAsArrayBuffer(this.inputFileImage)
        }
      }
    }
  }
</script>

<style scoped>

</style>
